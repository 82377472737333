const initialState = {
    addProductionOrder: false,
    loadingaddProductionOrder: false,
    getproductionSequence: null,
    getproductionorderData: null,
    getProductiionOrderDataPageSize: 0,
    getProductiondataToUpdate: null,
    getItempriceHistorydata: null,
    getItempriceHistorydataPageSize: 0,
    getProductionOrderLineSQData: null,

    addProductionorderLineData: false,
    handleModal: false,
    getProductionOrderLineDatapageSize: 0,
    getProductionOrderLineData: [],
    addSuppliersData: false,
    LoadingaddSuppliersData: false,
    getProductionStepsDatapageSize: null,
    getProductionStepsData: [],
    loadingUpdateProductionSteps: false,
    updateProductionStepsdata: false,
    getPackingStepsData: [],
    getPackingStepsMultipleData: [],
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "ADD_PRODUCTION_ORDER_LOADING":
            return {
                ...state,
                loadingaddProductionOrder: true,
            };
        case "ADD_PRODUCTION_ORDER_SUCCESS":
            return {
                ...state,
                addProductionOrder: true,
                loadingaddProductionOrder: false,
            };
        case "CHANGE_PRODUCTION_ORDER_SUCCESS_STATUS":
            return {
                ...state,
                addProductionOrder: false,
            };
        case "ADD_PRODUCTION_ORDER_UNSUCCESS":
            return {
                ...state,
                addProductionOrder: false,
                loadingaddProductionOrder: false,
            };
        case "":
            return {
                ...state,
                deleteWarehouseBin: false,
            };
        case "GET_PRODUCTION_ORDER_DATA":
            return {
                ...state,
                getproductionSequence: action.value,
            };
        case "GET_PDODUCTION_ORDER_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getproductionorderData: action.value.rows,
                    getProductiionOrderDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getproductionorderData: action.value.rows,
                };
            }

        case "GET_PDODUCTION_ORDER_DATA_UNSUCCESS":
            return {
                ...state,
                getproductionSequence: null,
            };

        case "PRODUCTION_DATA_TO_UPDATE":
            return {
                ...state,
                getProductiondataToUpdate: action.value,
            };

        case "LOADING_UPDATE_PRODUCTION_ORDER_STATUS":
            return {
                ...state,
                loadingupdProductiondata: true,
            };
        case "UPDATE_PRODUCTION_ORDER_DATA_SUCCESS":
            return {
                ...state,
                loadingupdProductiondata: false,
                updateProdctionOrderData: true,
            };
        case "CHANGE_PRODUCTION_ORDER_DATA_SUCCESS_STATUS":
            return {
                ...state,
                updateProdctionOrderData: false,
            };

        case "UPDATE_PRODUCTION_ORDER_DATA_UNSUCCES":
            return {
                ...state,
                updateProdctionOrderData: false,
                loadingupdProductiondata: false,
            };

        case "GET_PRODUCTION_ORDER_DATA_LINE_SQ_DATA":
            return {
                ...state,
                getProductionOrderLineSQData: action.value,
            };

        case "GET_PRODUCTION_ORDER_DATA_UNSUCESS":
            return {
                ...state,
                getProductionOrderLineSQData: null,
            };
        case "ADD_PRODUCTION_ORDER_LINE_LOADING":
            return {
                ...state,
                LoadingProductionorderLineData: true,
            };
        case "ADD_PRODUCTION_ORDER_LINE_SUCCESS":
            return {
                ...state,
                LoadingProductionorderLineData: false,
                addProductionorderLineData: true,
            };
        case "CHANGE_PRODUCTION_ORDER_LINE_SUCCESS_STATUS":
            return {
                ...state,
                addProductionorderLineData: false,
            };
        case "ADD_PRODUCTION_ORDER_LINE_UNSUCCESS":
            return {
                ...state,
                addProductionorderLineData: false,
                LoadingProductionorderLineData: false,
            };
        case "PRODUCTION_ORDER_MODAL_HANDLE":
            return {
                ...state,
                handleModal: action.value,
            };
        case "GET_PDODUCTION_ORDER_LINE_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getProductionOrderLineDatapageSize: action.value.pages,
                    getProductionOrderLineData: action.value.rows,
                };
            } else {
                return {
                    ...state,
                    getProductionOrderLineData: action.value,
                };
            }

        case "GET_PDODUCTION_ORDER_LINE_DATA_UNSUCCESS":
            return {
                ...state,
                getProductionOrderLineData: [],
            };

        case "STORE_PRODUCTION_ORDER_ID":
            return {
                ...state,
                storeProductionOrderID: action.value,
            };

        case "GET_PDODUCTION_STEPS_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getProductionStepsDatapageSize: action.value.pages,
                    getProductionStepsData: action.value.rows,
                };
            } else {
                return {
                    ...state,
                    getProductionStepsData: action.value,
                };
            }

        case "GET_PDODUCTION_STEPS_DATA_UNSUCCESS":
            return {
                ...state,
                getProductionStepsData: null,
            };
        case "LOADING_UPDATE_PRODUCTION_STEPS":
            return {
                ...state,
                loadingUpdateProductionSteps: true,
            };
        case "UPDATE_PDODUCTION_STEPS_DATA":
            return {
                ...state,
                loadingUpdateProductionSteps: false,
                updateProductionStepsdata: true,
            };
        case "UPDATE_PDODUCTION_STEPS_DATA_UNSUCCESS":
            return {
                ...state,
                loadingUpdateProductionSteps: false,
                updateProductionStepsdata: false,
            };

        case "GET_REPORT_DELIVERY_REPORT":
            return {
                ...state,
                report1: action.value,
            };

        case "GET_SELECTED_PRODUCTION_ITEM_DATA":
            return {
                ...state,
                WarehouseSelectedItemData: action.value,
            };

        case "GET_DELIVER_SCHEDULE_EXCEL_DATA":
            return {
                ...state,
                deliverScheduleExcelReportData: action.value,
            };

        case "GET_PACKING_STEPS_DATA":
            return {
                ...state,
                getPackingStepsData: action.value,
            };

        case "GET_PACKING_STEPS_DATA_UNSUCCESS":
            return {
                ...state,
                getPackingStepsData: [],
            };

        case "GET_PACKING_STEPS_MULTIPLE_DATA":
            return {
                ...state,
                getPackingStepsMultipleData: action.value,
            };
        case "GET_PACKING_STEPS_DATA_MULTIPLE_UNSUCCESS":
            return {
                ...state,
                getPackingStepsMultipleData: [],
            };

        case "GET_PROFITABILITY_REPORT":
            return {
                ...state,
                ProfitabilityItemList: action.value.profitabilityList,
                ProfitabilityMainExcelList: action.value.profitabilityMainExcelList,
            };

        case "GET_PDODUCTION_ORDER_BY_ID":
            return {
                ...state,
                getProductionOrderById: action.value,
            };

        case "GET_PROFITABILITY_ITEM_DETAILS":
            return {
                ...state,
                ProfitabilityItemDetailList: action.value,
            };

        case "REST_PROFITABILITY_ITEM_DETAILS":
            return {
                ...state,
                ProfitabilityItemDetailList: [],
            };
        case "GET_PROFITABILITY_ITEM_WISE_DETAILS":
            return {
                ...state,
                ProfitabilityItemWiseDetailList: action.value,
            };

        default:
            break;
    }

    return newState;
};
export default reducer;
